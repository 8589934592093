.BioDetailDescriptionPortfolio {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.2rem;
  color: #373530;
  line-height: 1.4;
  font-weight: 500;
}
.user-avatar-container {
  position: relative;
  display: inline-block;
}
.imagePlaceholder {
  border: 2px dashed black;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.userAvatar {
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 20px;
  border-radius: 50%;
  background-color: #d16a27;
  color: white;
  font-weight: bold;
}
.bigUserAvatar {
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  font-size: 40px;
  border-radius: 50%;
  background-color: #d16a27;
  color: white;
  font-weight: bold;
}
.CustomDropdownMenu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: max-content;
  padding: 10px;
  z-index: 1000;
}

.CustomDropdownItem {
  display: block;
  padding: 8px 12px;
  font-size: 16px;
  color: black;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}
.copyLink {
  width: 90%;
}
.aritstNameMoodBoard {
  font-size: 28px;
  position: absolute;
  bottom: 3.5%;
  left: 4%;
  color: white;
  background-color: #60606059;
}
.cursor-pointer {
  cursor: pointer;
}
.orderButtonContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  padding: 5px 0;
  z-index: 10;

  background-color: rgb(0, 0, 0, 0.5);
}
.miniVersion {
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-order-arrows {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: end;
  padding: 20px 0;
}

.miniVersionArrow {
  padding: 0 0;
  align-items: start;
}

.bgTransparentBlack {
  z-index: 10;

  background-color: rgb(0, 0, 0, 0.5);
}
.CustomDropdownItem:hover {
  background-color: #f0f0f0;
}
.BioDetailDescriptionPortfolio > h1 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.4rem;
  color: #373530;
  line-height: 1;
  font-weight: 600;
}

.BioDetailDescriptionPortfolio > h2 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.2rem;
  color: #373530;
  line-height: 1;
  font-weight: 600;
}

.BioDetailDescriptionPortfolio > p {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.06rem;
  margin: 0px;
}
.right_content_bio {
  width: 75%;
  padding-left: 1.5vw;
}
.mid_content_bio {
  width: 25%;
}
.error {
  color: red;
}
.main-container-awards {
  display: flex;
  flex-direction: column;
  width: 80%;
}
.logoutImage {
  width: 20px;
}
.personalWorksMainContainer {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.awardContainer {
  margin: 0px 15px 15px 15px;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  column-gap: 20px;
  height: fit-content;
}

.h4CommonStyle {
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 0px;
}

.awardLinkStyle {
  color: #d16a27;
  font-size: 15px;
  margin-top: 15px;
}

.awardLinkStyle a {
  color: #d16a27;
  font-size: 14px;
  text-decoration: underline;
}
.awardsWithImage {
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: 0.5fr 1fr;
  gap: 10px;
}
.gallery {
  display: flex; /* Use flexbox to lay out columns next to each other */
  justify-content: center; /* Center the columns in the gallery container */
  gap: 10px; /* Space between columns */
}

.column {
  flex: 1; /* Each column takes an equal width */
  display: flex;
  flex-direction: column; /* Stack images vertically within each column */
  gap: 10px; /* Space between images */
}

.column .artistImage {
  width: 100%;
  height: auto;
  object-fit: cover; /* Cover to ensure images fit well */
}
.ribbon-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
  width: 100px;
  height: 100px;
  pointer-events: none;
}

.ribbon {
  position: absolute;
  top: 30px;
  left: -30px;
  width: 140px;
  transform: rotate(-45deg);
  background-color: #d14523;
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  padding: 5px 0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  pointer-events: auto;
}

input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
}

/* Width of the scrollbar */
::-webkit-scrollbar {
  width: 6px; /* Thin scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; /* Or any other background color */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d16a27; /* Orange color */
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d16a27; /* Slightly darker orange */
}
/* Scrollbar width */
* {
  scrollbar-width: thin;
  scrollbar-color: #d16a27 transparent;
}

@media screen and (max-width: 1340px) {
  .right_content_bio {
    width: 60%;
  }
  .mid_content_bio {
    width: 40%;
  }
}
@media screen and (max-width: 991px) {
  .BioDetailDescriptionPortfolio > h1,
  h2,
  a,
  h3,
  h4,
  h5,
  p {
    font-size: 16px !important;
    line-height: 1.5;
  }
  .personalWorksMainContainer {
    grid-template-columns: repeat(3, 1fr);
  }
  .main-container-awards {
    width: 100%;
    row-gap: 10px;
  }

  .allArtistsButtons {
    font-size: 13px !important;
  }

  .elementaryContainer {
    grid-template-columns: 1fr !important;
  }

  .elementaryLibraryIcon {
    width: 50px !important;
  }

  .elemtaryElementsContainer {
    grid-template-columns: 1fr 1fr !important;
  }

  .elementaryBoxIcon {
    width: 40px !important;
  }

  .elementaryDiv {
    margin-bottom: 20px;
  }

  .columns2Icon {
    width: 60px !important;
  }

  .columns3Icon {
    width: 90px !important;
  }

  .ideaboardMainDiv {
    width: 95% !important;
  }

  .ideaBoardsButtons {
    flex-direction: column !important;
  }

  .allArtistsButtons {
    margin-bottom: 10px !important;
    font-size: 12px !important;
  }

  .myShannonNavButtons {
    display: flex;
    flex-direction: column;
  }

  .myShannonNavDiv {
    flex-direction: column;
  }

  .myShannonContactLink {
    width: 100%;
  }

  .allArtistsButtons {
    width: 100%;
  }
  .addImageMyshannon {
    margin: 10px;
  }

  .pIdeaBoard {
    font-size: 12px !important;
    text-align: center;
  }

  .moodBoardElementGrid {
    column-gap: 10px !important;
    min-height: auto;
  }

  .imagescontainer {
    height: fit-content !important;
  }

  .welcomeTo {
    margin-left: 0px !important;
    margin-top: 50px;
  }

  .myHomeCont {
    display: grid;
    grid-template-columns: 1fr !important;
    column-gap: 20px !important;
  }

  .myHomeFavP {
    color: #b0b0b0;
    font-size: 0.65vw;
    font-weight: 500;
    margin-left: 20px;
    height: 40px;
  }

  .myHomeFavPAdd {
    color: #b0b0b0;
    font-size: 0.65vw;
    font-weight: 500;
    margin-left: 20px;
    height: 40px;
    margin-bottom: 50px;
  }

  .myHomeSorting {
    opacity: 0;
    margin-bottom: 50px !important;
  }

  .myHomeSortingVisible {
    margin-bottom: 50px !important;
  }

  .myHomeFavDiv {
    height: 500px !important;
    padding-left: 20px;
  }

  .myHomeButtonsAdd {
    margin-left: 0px;
    font-size: 11px !important;
    padding: 6px 12px;
  }
}
@media screen and (max-width: 576px) {
  .awardsWithImage {
    grid-template-columns: none;
    grid-template-rows: auto;
    row-gap: 10px;
  }
  .mobMenu a {
    display: block;
    width: 100%;
    margin: 10px 0;
  }

  .carouselArrow {
    width: 10%;
  }
  .right_content_bio {
    width: 100%;
    padding-left: 0;
  }

  .mid_content_bio {
    width: 100%;
  }
  .bioMainImage {
    width: 40%;
  }
  .personalWorksMainContainer {
    grid-template-columns: repeat(2, 1fr);
  }
  .allArtistsButtons {
    font-size: 13px !important;
  }

  .elementaryContainer {
    grid-template-columns: 1fr !important;
  }

  .elementaryLibraryIcon {
    width: 50px !important;
  }

  .elemtaryElementsContainer {
    grid-template-columns: 1fr 1fr !important;
  }

  .elementaryBoxIcon {
    width: 40px !important;
  }

  .elementaryDiv {
    margin-bottom: 20px;
  }

  .columns2Icon {
    width: 60px !important;
  }

  .columns3Icon {
    width: 90px !important;
  }

  .ideaboardMainDiv {
    width: 95% !important;
  }

  .ideaBoardsButtons {
    flex-direction: column !important;
  }

  .allArtistsButtons {
    margin-bottom: 10px !important;
    font-size: 12px !important;
  }

  .myShannonNavButtons {
    display: flex;
    flex-direction: column;
  }

  .myShannonNavDiv {
    flex-direction: column;
  }

  .myShannonContactLink {
    width: 100%;
  }

  .allArtistsButtons {
    width: 100%;
  }
  .addImageMyshannon {
    margin: 10px;
  }

  .pIdeaBoard {
    font-size: 12px !important;
    text-align: center;
  }

  .moodBoardElementGrid {
    column-gap: 10px !important;
    min-height: auto;
  }

  .imagescontainer {
    height: fit-content !important;
  }
}
